import React from "react";
import { graphql } from 'gatsby';

import Layout from '../components/layout'

const divStyle = {marginTop: "10px"};

const AchievementsPage = ({ data }) => (
  <Layout>
    <h1> Achievements:</h1>{data.allNodeAchievement.edges.sort((node, node2) => {
      if(typeof(node) === 'undefined' || typeof(node2) === 'undefined') {return 0;} return(node.node.field_quarter >= node2.node.field_quarter ? (node.node.field_quarter === node2.node.field_quarter ? 0 : 1) : -1);
      }).map(({ node }) => (<div style={divStyle}> <h3>{node.field_quarter} - {node.title}</h3> <div dangerouslySetInnerHTML={{ __html: node.body.value }} /> </div>))}
  </Layout>
);
export const query = graphql` query allNodeAchievement {
  allNodeAchievement {
    edges {
      node {
        id
        title
        body {
          value
          format
          processed
          summary
        }
        field_quarter
      }
    }
  }
} `;
export default AchievementsPage;
